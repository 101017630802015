<template>
  <div class="home">
    <distributor-map
    ref="map"
    :settings="settings"
    :items="items"
    :geography="$options.countriesData"
    :displayField="displayField"/>
  </div>
</template>

<script>
// @ is an alias to /src
import COUNTRIES_LOOKUP_JSON from '@/map/country_paths_lookup.json';
import DistributorMap from '@/components/DistributorMap.vue';

export default {
  name: 'Home',
  countriesData: COUNTRIES_LOOKUP_JSON,
  components: {
    DistributorMap,
  },
  data: () => ({
    displayField: { name: 'value', type: 'qualitative', text: 'numericalCoolness (quantitative)' },
    settings: {
      bins: [
        {
          values: [
            'Distributor',
          ],
          color: '#414145',
          label: 'Distributor',
        },
      ],
      colors: {
        background: {
          color: '#fcfcfc',
          name: 'Background',
          key: 'background',
        },
        noData: {
          color: '#e6e6e6',
          name: 'No Data',
          key: 'noData',
        },
        primaryStroke: {
          color: '#111111',
          name: 'Primary Stroke',
          key: 'primaryStroke',
        },
        focus: {
          color: '#2e36c9',
          name: 'Focus/Hover',
          key: 'focus',
        },
      },
      legend: {
        colors: {
          background: {
            color: {
              r: 13,
              g: 13,
              b: 13,
              a: 1,
            },
            name: 'Background',
            key: 'background',
          },
        },
        patch: {
          width: 25,
          height: 25,
        },
      },
      displayField: {
        name: 'categoricalCoolness',
        type: 'qualitative',
        text: 'categoricalCoolness (qualitative)',
      },
      primaryStrokeWidth: 0.2,
    },
    items: [
      { name: 'Argentina', id: 'ARG', value: 'Distributor' },
      { name: 'Australia', id: 'AUS', value: 'Distributor' },
      { name: 'Brazil', id: 'BRA', value: 'Distributor' },
      { name: 'Canada', id: 'CAN', value: 'Distributor' },
      { name: 'Chile', id: 'CHL', value: 'Distributor' },
      { name: 'Colombia', id: 'COL', value: 'Distributor' },
      { name: 'Ecuador', id: 'ECU', value: 'Distributor' },
      { name: 'Iceland', id: 'ISL', value: 'Distributor' },
      { name: 'Israel', id: 'ISR', value: 'Distributor' },
      { name: 'Italy', id: 'ITA', value: 'Distributor' },
      { name: 'Lebanon', id: 'LBN', value: 'Distributor' },
      { name: 'Mexico', id: 'MEX', value: 'Distributor' },
      { name: 'New Zealand', id: 'NZL', value: 'Distributor' },
      { name: 'Pakistan', id: 'PAK', value: 'Distributor' },
      { name: 'Panama', id: 'PAN', value: 'Distributor' },
      { name: 'Papua New Guinea', id: 'PNG', value: 'Distributor' },
      { name: 'Poland', id: 'POL', value: 'Distributor' },
      { name: 'Qatar', id: 'QAT', value: 'Distributor' },
      { name: 'Romania', id: 'ROU', value: 'Distributor' },
      { name: 'Russian Federation', id: 'RUS', value: 'Distributor' },
      { name: 'Slovenia', id: 'SVN', value: 'Distributor' },
      { name: 'Uruguay', id: 'URY', value: 'Distributor' },
    ],
  }),
};
</script>
